
import { defineComponent } from 'vue'

import PuzzleSecret from './components/PuzzleSecret.vue'

export default defineComponent({
  name: 'App',
  components: { PuzzleSecret }
})

