<template>
  <div class="qbootstrap-hero" style="height: 100px; overflow-y: hidden; margin-bottom: 100px;" data-section="home">
    <div class="qbootstrap-overlay"></div>
    <div class="qbootstrap-cover text-center" data-stellar-background-ratio="0.5" style="background-image: url(images/cover_bg_3.jpg);">
      <div class="display-t">
        <div class="display-tc">
          <div class="container">
            <div class="col-md-10 col-md-offset-1">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
