<template>
<header role="banner" id="qbootstrap-header">
  <div class="container">
    <!-- <div class="row"> -->
      <nav class="navbar navbar-default">
        <div class="navbar-header">
          <!-- Mobile Toggle Menu Button -->
      <a href="#" class="js-qbootstrap-nav-toggle qbootstrap-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i></i></a>
            <a class="navbar-brand" href="/">Wedding</a>
        </div>
        <div id="navbar" class="navbar-collapse collapse">
          <ul class="nav navbar-nav navbar-right">
            <li><a href="/"><span>Home</span></a></li>
            <li><a href="/" data-nav-section="groom-bride"><span>Groom &amp; Bride</span></a></li>
            <li><a href="/" data-nav-section="story"><span>Love Story</span></a></li>
            <li><a href="/" data-nav-section="people"><span>People</span></a></li>
            <li><a href="/" data-nav-section="when-where"><span>When &amp; Where</span></a></li>
            <li><a href="/details"><span>Details & FAQ</span></a></li>
            <li class="rsvp-button"><a href="https://forms.gle/o2nBUFHNPn83pige8" target="_blank" title="Please RSVP by September 1st!"><span>RSVP</span></a></li>
          </ul>
        </div>
      </nav>
    <!-- </div> -->
  </div>
</header>
</template>
