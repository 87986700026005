<template>
  <div id="qbootstrap-pre-wedding-events" data-section="pre-wedding-events">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Escape Room Puzzles!</h1>
          <p>Because Tim and Brooke share a passion for puzzles and escape rooms, several games and puzzles will be scattered throughout the wedding venue for guests to work through and solve together. Some may be well-hidden and quite a challenge to figure out, so keep your eyes peeled!</p>
        </div>
      </div>
    </div>
  </div>
</template>
