<template>
  <div id="qbootstrap-when-where" data-section="when-where">
    <div class="container">
      <div class="row animate-box">
        <div class="col-md-8 col-md-offset-2">
          <div class="col-md-12 text-center section-heading svg-sm colored">
            <img src="images/flaticon/svg/005-two.svg" class="svg" alt="Free HTML5 Bootstrap Template by QBootstrap.com">
            <h2>Hotel, Ceremony &amp; Party</h2>
            <div class="row">
            <div class="col-md-10 col-md-offset-1 subtext">
              <h3>In one location and full of puzzles for all.</h3>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="row row-bottom-padded-sm">
        <div class="col-md-6 text-center animate-box">
          <div class="wedding-events">
            <div class="ceremony-bg" style="background-image: url(images/desmond-hotel.webp);"></div>
            <div class="desc">
              <h3>Hotel</h3>
              <div class="row">
                <div class="col-md-12">
                  <div class="date">
                    <i class="icon-calendar"></i>
                    <span>Saturday & Sunday </span>
                    <span>15 & 16 Oct. 2022</span>
                  </div>
                </div>
              </div>
              <p>A block of rooms have been reserved for both Saturday and Sunday night at the Desmond Hotel, located about 10 minutes away from Anthony Wayne House. Shuttle busses will be available to transport all guests between the hotel and the wedding venue.</p>
              <p><a href="/details" data-nav-section="hotel-details" class="btn btn-primary btn-sm">Learn more</a></p>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-center animate-box">
          <div class="wedding-events">
            <div class="ceremony-bg" style="background-image: url(images/wedding-ceremony.webp);"></div>
            <div class="desc">
              <h3>Wedding Ceremony</h3>
              <div class="row">
                <div class="col-md-2 col-md-push-5">
                  <div class="icon-tip">
                    <span class="icon"><i class="icon-heart-o"></i></span>
                  </div>
                </div>
                <div class="col-md-5 col-md-pull-1">
                  <div class="date">
                    <i class="icon-calendar"></i>
                    <span>Sunday</span>
                    <span>16 Oct. 2022</span>
                  </div>
                </div>
                <div class="col-md-5 col-md-pull-1">
                  <div class="date">
                    <i class="icon-clock2"></i>
                    <span>3:00 PM</span>
                    <span>5:00 PM</span>
                  </div>
                </div>
              </div>
              <p>The ceremony will be held at Anthony Wayne House, a historic building nestled in the woods just outside of Philadelphia. The ceremony kicks off at 4pm sharp.</p>
              <p><a href="/details" data-nav-section="the-main-event" target="_blank" class="btn btn-primary btn-sm">Learn more</a></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-bottom-padded-sm">
        <div class="col-md-6 text-center animate-box">
          <div class="wedding-events">
            <div class="ceremony-bg" style="background-image: url(images/wedding-party.webp);"></div>
            <div class="desc">
              <h3>Wedding Reception</h3>
              <div class="row">
                <div class="col-md-2 col-md-push-5">
                  <div class="icon-tip">
                    <span class="icon"><i class="icon-heart-o"></i></span>
                  </div>
                </div>
                <div class="col-md-5 col-md-pull-1">
                  <div class="date">
                    <i class="icon-calendar"></i>
                    <span>Sunday</span>
                    <span>16 Oct. 2022</span>
                  </div>
                </div>
                <div class="col-md-5 col-md-pull-1">
                  <div class="date">
                    <i class="icon-clock2"></i>
                    <span>5:00 PM</span>
                    <span>9:00 PM</span>
                  </div>
                </div>
              </div>
              <p>After the ceremony comes the party! Signature cocktails, a bacon bar, dinner, and an open bar for all.</p>
              <p><a href="/details" data-nav-section="hotel-details" target="_blank" class="btn btn-primary btn-sm">Learn more</a></p>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-center animate-box">
          <div class="wedding-events">
            <div class="ceremony-bg" style="background-image: url(images/fox-and-hound-pub.jpg);"></div>
            <div class="desc">
              <h3>Wedding Afterparty</h3>
              <div class="row">
                <div class="col-md-2 col-md-push-5">
                  <div class="icon-tip">
                    <span class="icon"><i class="icon-heart-o"></i></span>
                  </div>
                </div>
                <div class="col-md-5 col-md-pull-1">
                  <div class="date">
                    <i class="icon-calendar"></i>
                    <span>Sunday</span>
                    <span>16 Oct. 2022</span>
                  </div>
                </div>
                <div class="col-md-5 col-md-pull-1">
                  <div class="date">
                    <i class="icon-clock2"></i>
                    <span>9:00 PM</span>
                    <span>2:00 AM</span>
                  </div>
                </div>
              </div>
              <p>After the reception, we will catch a shuttle back to the Desmond Hotel for an afterparty at the Fox & Hounds Pub.</p>
              <p><a href="https://www.foxandhoundsmalvern.com/" target="_blank" class="btn btn-primary btn-sm">Learn more</a></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div id="map" class="qbootstrap-map"></div>
        </div>
      </div>
    </div>
  </div>
</template>
