<template>
  <div id="qbootstrap-story" data-section="story">
    <div class="container">
      <div class="row animate-box">
        <div class="col-md-8 col-md-offset-2">
          <div class="col-md-12 text-center section-heading svg-sm-2">
            <img src="images/flaticon/svg/003-luxury.svg" class="svg" alt="Free HTML5 Bootstrap Template by QBootstrap.com">
            <h2>Our Love Story</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ul class="timeline animate-box">
            <li class="animate-box">
              <div class="timeline-badge" style="background-image:url(images/amsterdam.webp);"></div>
              <div class="timeline-panel">
                <div class="overlay"></div>
                <div class="timeline-heading">
                  <h3 class="timeline-title">First We Meet</h3>
                  <span class="date">December 25, 2014</span>
                </div>
                <div class="timeline-body">
                  <p>Having grown up on opposite sides of the world, Tim and Brooke serendipitously found themselves in the same city at the same time. Brooke was on holiday in Amsterdam while teaching English in Spain, while Tim had recently moved there for work. Being alone in a strange city, they each had nowhere to go on Christmas Day 2015, so they individually decided to celebrate at a Couchsurfing party. Tim brought some wine, but forgot an opener; luckily, Brooke had him covered. Tim later agreed to walk Brooke back across town to her hostel for trivia.</p>
                </div>
              </div>
            </li>
            <li class="timeline-inverted animate-box">
              <div class="timeline-badge" style="background-image:url(images/muiderslot-castle.jpg);"></div>
              <div class="timeline-panel">
                <div class="overlay overlay-2"></div>
                <div class="timeline-heading">
                  <h3 class="timeline-title">First Date</h3>
                  <span class="date">December 27, 2014</span>
                </div>
                <div class="timeline-body">
                  <p>Two days later, Tim and Brooke travelled to a nearby town to explore Muiderslot Castle, where they tried on metal armour, learned about the castle's history, then sampled mulled wine and mead. On the way back, they caught the bus in the wrong direction and got lost together. As intrepid adventurers, Brooke and Tim enjoyed figuring out their way back, which took them through a quaint village.</p>
                </div>
              </div>
            </li>
            <li class="animate-box">
              <div class="timeline-badge" style="background-image:url(images/londonbusride.jpg);"></div>
              <div class="timeline-panel">
                <div class="overlay"></div>
                <div class="timeline-heading">
                  <h3 class="timeline-title">An overseas holiday</h3>
                  <span class="date">January 3, 2015</span>
                </div>
                <div class="timeline-body">
                  <p>Brooke's journey continued on to London, where she planned to meet her brother. Stuck in Amsterdam with little to do, Tim decided to join her. There they embarked on a Harry Potter journey, and made new friends along the way.</p>
                </div>
              </div>
            </li>
            <li class="timeline-inverted animate-box">
              <div class="timeline-badge" style="background-image:url(images/salamanca-church.jpg);"></div>
              <div class="timeline-panel">
                <div class="overlay overlay-2"></div>
                <div class="timeline-heading">
                  <h3 class="timeline-title">Visiting Salamanca</h3>
                  <span class="date">March 31, 2015</span>
                </div>
                <div class="timeline-body">
                  <p>Brooke was living in Salamanca, Spain, while Tim still resided in Amsterdam. Of course, they couldn't be separated for long, so Tim flew down to enjoy the warm weather with Brooke. There, they discovered that "burger" is not the same thing in the US as it is in Australia.</p>
                </div>
              </div>
            </li>
            <li class="animate-box">
              <div class="timeline-badge" style="background-image:url(images/binghamton-brooke.jpg);"></div>
              <div class="timeline-panel">
                <div class="overlay"></div>
                <div class="timeline-heading">
                  <h3 class="timeline-title">A happy home</h3>
                  <span class="date">November 5, 2015</span>
                </div>
                <div class="timeline-body">
                  <p>After almost a year of long distance dating and chatting every single day, Brooke moved back to the United States to volunteer for AmeriCorps. Tim followed her there shortly after. They rented an apartment in Binghamton, NY, where they lived together for the first time.</p>
                </div>
              </div>
            </li>
            <li class="timeline-inverted animate-box">
              <div class="timeline-badge" style="background-image:url(images/spaceneedle.jpg);"></div>
              <div class="timeline-panel">
                <div class="overlay overlay-2"></div>
                <div class="timeline-heading">
                  <h3 class="timeline-title">Westbound!</h3>
                  <span class="date">August 5, 2016</span>
                </div>
                <div class="timeline-body">
                  <p>In a fortuitous turn of events, Tim’s job requested he move to Seattle, Washington and  Brooke was accepted into Washington State University within hours of each other. They packed their bags and moved west, where they would remain for the next 4 years, enjoying the big city life, craft beer, and lack of sunshine.</p>
                </div>
              </div>
            </li>
            <li class="animate-box">
              <div class="timeline-badge" style="background-image:url(images/brisbane.jpg);"></div>
              <div class="timeline-panel">
                <div class="overlay"></div>
                <div class="timeline-heading">
                  <h3 class="timeline-title">Back to Australia</h3>
                  <span class="date">April 30, 2020</span>
                </div>
                <div class="timeline-body">
                  <p>Due to their city becoming a plagueland (and Tim's desire for more sunshine), they picked up and left Seattle to return to Tim's hometown of Brisbane, Australia. They moved into their shared unit in the Nundah suburb, where they currently reside.</p>
                </div>
              </div>
            </li>
            <li class="timeline-inverted animate-box">
              <div class="timeline-badge" style="background-image:url(images/proposal.jpg);"></div>
              <div class="timeline-panel">
                <div class="overlay overlay-2"></div>
                <div class="timeline-heading">
                  <h3 class="timeline-title">Engagement!</h3>
                  <span class="date">July 10, 2020</span>
                </div>
                <div class="timeline-body">
                  <p>After completing over 40 escape rooms together, Tim decided to devise a special escape room for Brooke. He organized a side quest for Brooke in a pirate-themed room, where he hid an engagement ring for her in the final treasure chest. She immediately saw through his elaborate ruse, but loved it all the same.</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
