<template>
  <div id="qbootstrap-pre-wedding-events" data-section="pre-wedding-events">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Hey there mystery hunter!</h1>
          <p>You found our secret puzzles page. It's not done yet, but we're glad you're curious enough to find it. You're going to enjoy our wedding ;)</p>
        </div>
      </div>
    </div>
  </div>
</template>
