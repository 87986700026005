import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroSection = _resolveComponent("HeroSection")!
  const _component_CoupleInfo = _resolveComponent("CoupleInfo")!
  const _component_CountdownSection = _resolveComponent("CountdownSection")!
  const _component_GroomBride = _resolveComponent("GroomBride")!
  const _component_OurStory = _resolveComponent("OurStory")!
  const _component_BridalParty = _resolveComponent("BridalParty")!
  const _component_WhenWhere = _resolveComponent("WhenWhere")!
  const _component_RSVP = _resolveComponent("RSVP")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeroSection),
    _createVNode(_component_CoupleInfo),
    _createVNode(_component_CountdownSection),
    _createVNode(_component_GroomBride),
    _createVNode(_component_OurStory),
    _createVNode(_component_BridalParty),
    _createVNode(_component_WhenWhere),
    _createVNode(_component_RSVP)
  ], 64))
}