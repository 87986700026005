<template>
  <div id="qbootstrap-faq" data-section="the-main-event">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>The Main Event</h1>
          <p>Both the wedding ceremony and reception will take place at on Sunday, October 16, 2022 at Anthony Wayne House: <a href="https://www.google.com/maps/place/2049+Waynesborough+Rd,+Paoli,+PA+19301,+USA/">2049 Waynesborough Road, Paoli, PA 19301</a></p>
          <p>Guests can arrive from 3:15pm, there will be shuttles leaving the hotel at 3:00pm and 3:30pm. The ceremony will start at 4:00pm sharp and will go until about 4:30pm. A cocktail hour will follow, where you will enjoy our signature cocktails, a bacon bar, butlered hors d'oeuvres, and some yard games.</p>
          <p>After the cocktail hour, guests will convene for the reception where dinner and drinks will be served, embarrassing stories will be told, music will blast, and well-mannered frivolity will be had.</p>
          <p>At 9:00 pm, we will be shuttled back to the Desmond Hotel for an afterparty at the Fox & Hounds Pub in the hotel lobby area.</p>
          <p>The Dress Code is Semi-Formal - no jeans, shorts, or t-shirts please. The temperature will be around 5 to 15 degrees celcius, so you'll want to wear something warm.</p>
        </div>
      </div>
    </div>
  </div>
</template>
