import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App'
import HomePage from './HomePage'
import DetailsPage from './DetailsPage'
import PuzzlesPage from './PuzzlesPage'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: HomePage },
    { path: '/details', component: DetailsPage },
    { path: '/puzzle', component: PuzzlesPage }
  ]
})

const app = createApp(App)
app.use(router)
app.mount('#app')
