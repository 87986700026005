<template>
  <div id="qbootstrap-pre-wedding-events" data-section="pre-wedding-events">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Pre Wedding Events</h1>
          <p>Because most guests are travelling from overseas, we are planning a five-day road trip from Monday, October 10 to Friday, October 14. We will be travelling to Washington DC, New York City, and Philadelphia, where we will visit national museums, shoot guns, eat American-sized portions, and more!</p>
          <p>We will organize a party bus to transport us between cities, where we will stay in hotels or AirBnbs. The trip is open to anyone who is keen to participate. Please let us know if you plan to join us ASAP, so that we know how many people we will need to accommodate for.</p>
        </div>
      </div>
    </div>
  </div>
</template>
