<template>
  <div id="qbootstrap-honeymoon-fund-details" data-section="honeymoon-fund">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Wedding Gifts</h1>
          <p>Your presence at our wedding is present enough! However, for friends and family who have expressed an interest, we've created a <a href="https://www.hitchd.com/cocorobinson2022" target="_blank">honeymoon fund with hitchd here</a></p>
        </div>
      </div>
    </div>
  </div>
</template>
