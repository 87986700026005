<template>
   <div id="qbootstrap-groom-bride" data-section="groom-bride">
    <div class="container">
      <div class="row animate-box">
        <div class="col-md-8 col-md-offset-2">
          <div class="col-md-12 text-center section-heading svg-sm-2 colored">
            <h2>Groom &amp; Bride</h2>
            <p>Geeky, Passionate, Intellectual. Lovers of humanity and all its puzzles.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="couple groom text-center animate-box">
            <img src="images/groom.jpg" class="img-responsive" alt="Tim Robinson">
            <div class="desc">
              <h2>Tim Robinson</h2>
              <p>Coder by day, crypto enthusiast by night. Intrigued by intellectual conversations, especially about big ideas.</p>
              <ul class="social social-circle">
                <li><a href="https://twitter.com/timjrobinson"><i class="icon-twitter"></i></a></li>
                <li><a href="https://facebook.com/timjrobinson"><i class="icon-facebook"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="couple bride text-center animate-box">
            <img src="images/bride.jpg" class="img-responsive" alt="Brooke Coco">
            <div class="desc">
              <h2>Brooke Ann Coco</h2>
              <p>A lover of culture and humanity. Master of puzzles, solver of problems.</p>
              <ul class="social social-circle">
                <li><a href="https://facebook.com/brookeanncoco"><i class="icon-facebook"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
