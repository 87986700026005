<template>
  <div id="qbootstrap-countdown" data-stellar-background-ratio="0.5" style="background-image: url(images/SunshineCoastEngagementBrookeTim-4.jpg);" data-section="wedding-day">
    <div class="overlay"></div>
    <div class="display-over">
      <div class="container">
        <div class="row animate-box">
          <div class="col-md-12 section-heading text-center svg-sm colored">
            <img src="images/flaticon/svg/006-flower-bell-outline-design-variant-with-vines-and-leaves.svg" class="svg" alt="Vines and Leaves">
            <h2 class="">The Wedding Day</h2>
            <span class="datewed">Sunday, October 16, 2022 at 4pm</span>
          </div>
        </div>
        <div class="row animate-box">
          <div class="col-md-8 col-md-offset-2 text-center">
            <p class="countdown">
              <span id="days"></span>
              <span id="hours"></span>
              <span id="minutes"></span>
              <span id="seconds"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
