
import { defineComponent } from 'vue'

import HeroSection from './components/HeroSection.vue'
import CoupleInfo from './components/CoupleInfo.vue'
import CountdownSection from './components/CountdownSection.vue'
import GroomBride from './components/GroomBride.vue'
import OurStory from './components/OurStory.vue'
import BridalParty from './components/BridalParty.vue'
import WhenWhere from './components/WhenWhere.vue'
import RSVP from './components/RSVP.vue'

export default defineComponent({
  name: 'App',
  components: { HeroSection, CoupleInfo, CountdownSection, GroomBride, OurStory, BridalParty, WhenWhere, RSVP }
})

