<template>
  <div id="qbootstrap-contact-details" data-section="contact-details">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Contact Details</h1>
          <h2>Tim</h2>
          <ul>
            <li>Email: tim@timjrobinson.com</li>
            <li>Phone (Australia): +61405297211</li>
            <li>WhatsApp: +16073499592</li>
            <li>Signal: +61405297211</li>
          </ul>
          <h2>Brooke</h2>
          <ul>
            <li>Email: brookers713@gmail.com</li>
            <li>Phone (Australia): +61451279858</li>
            <li>WhatsApp: +61451279858</li>
            <li>Signal: +61451279858</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
