import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroSectionSmall = _resolveComponent("HeroSectionSmall")!
  const _component_WeddingDetails = _resolveComponent("WeddingDetails")!
  const _component_HotelDetails = _resolveComponent("HotelDetails")!
  const _component_TransportationDetails = _resolveComponent("TransportationDetails")!
  const _component_PuzzleDetails = _resolveComponent("PuzzleDetails")!
  const _component_BucksHens = _resolveComponent("BucksHens")!
  const _component_PreWeddingEvents = _resolveComponent("PreWeddingEvents")!
  const _component_HoneymoonFundDetails = _resolveComponent("HoneymoonFundDetails")!
  const _component_ContactDetails = _resolveComponent("ContactDetails")!
  const _component_FAQ = _resolveComponent("FAQ")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeroSectionSmall),
    _createVNode(_component_WeddingDetails),
    _createVNode(_component_HotelDetails),
    _createVNode(_component_TransportationDetails),
    _createVNode(_component_PuzzleDetails),
    _createVNode(_component_BucksHens),
    _createVNode(_component_PreWeddingEvents),
    _createVNode(_component_HoneymoonFundDetails),
    _createVNode(_component_ContactDetails),
    _createVNode(_component_FAQ)
  ], 64))
}