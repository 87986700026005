<template>
  <div id="qbootstrap-faq" data-section="faq">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Flying In</h1>
          <p>The nearest airport to the wedding venue is the Philadelphia Airport (PHL), which is only about a half hour drive. Located about an hour and a half away by car, Newark Liberty International Airport (EWR) is the next closest international airport.</p>
          <p>For those travelling from Australia, there are currently some good deals for flights from Brisbane (BNE) to Los Angeles (LAX) using Qantas points. From Los Angeles, there are several cheap flight options to Philadelphia or Newark.</p>
          <h1>Getting Around</h1>
          <p>The quickest and easiest way to get around is by car. Depending on how long you plan to stay in the area, you have the options of either renting a car from whichever airport in which you arrive, or organizing transportation through ride-sharing apps like Uber or Lyft. For those traveling in from overseas, the United States does permit driving with a valid foreign license.</p>
          <p>Septa and Amtrak both have train lines that run from Philadelphia to Paoli. The Desmond Hotel has a shuttle bus which is able to pick you up from Paoli station, you'll need to arrange this with them beforehand. You could also catch an Uber or Taxi from the railway station to the Hotel.</p>
          <p>The Desmond Hotel is only a 10 minute drive away from Anthony Wayne House. Shuttles will be available to transport guest between the two venues both before and after the wedding.</p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</template>
