<template>
  <div id="qbootstrap-pre-wedding-events" data-section="pre-wedding-events">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Bucks and Hens Parties</h1>
          <p>We will be celebrating our Hens/ Bucks parties in New York City on Thursday, October 13, 2022. They are open to anyone who will be in town on that day. If you plan to join us, please let us know ASAP so that we can book the proper accommodations.</p>
        </div>
      </div>
    </div>
  </div>
</template>
