<template>
  <div id="qbootstrap-couple" class="qbootstrap-section-gray">
    <div class="container">
      <div class="row animate-box">
        <div class="col-md-8 col-md-offset-2 animate-box">
          <div class="col-md-12 text-center section-heading svg-sm colored">
            <img src="images/flaticon/svg/005-two.svg" class="svg" alt="Decorative Graphics">
            <h2>We're Getting Married!</h2>
            <p>At a rustic autumn retreat at Anthony Wayne House</p>
            <p><strong>on October 16, 2022 &mdash; Paoli, USA</strong></p>
          </div>
        </div>
      </div>
      <div class="row animate-box">
        <div class="col-md-8 col-md-offset-2 text-center">
          <div class="col-md-5 col-sm-5 col-xs-5 nopadding">
            <img src="images/groom.jpg" class="img-responsive" alt="Timothy Robinson">
            <h3>Timothy Robinson</h3>
            <span>Groom</span>
          </div>
          <div class="col-md-2 col-sm-2 col-xs-2 nopadding"><h2 class="amp-center"><img src="images/flaticon/svg/003-luxury.svg" class="svg img-responsive" alt="Decoration"></h2></div>
          <div class="col-md-5 col-sm-5 col-xs-5 nopadding">
            <img src="images/bride.jpg" class="img-responsive" alt="Brooke Ann Coco">
            <h3>Brooke Ann Coco</h3>
            <span>Bride</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
