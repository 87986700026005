<template>
  <div id="qbootstrap-hotel-details" data-section="hotel-details">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Hotel Details</h1>
          <p>We recommend that guests stay at <a href="https://www.desmondgv.com/">The Desmond Malvern</a>. This is where guests will be shuttled to and from the wedding. It is also where the afterparty will be held following the reception. We have booked a block of 35 rooms for both Saturday (10/15) and Sunday (10/16) night. To benefit from the hotel block discount use our wedding group code "COC". The price should come to $164 USD per night. Please secure all bookings before Saturday, September 17, 2022 to guarantee your spot.</p>
        </div>
      </div>
    </div>
  </div>
</template>
