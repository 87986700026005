
import { defineComponent } from 'vue'

import HeroSectionSmall from './components/HeroSectionSmall.vue'
import WeddingDetails from './components/WeddingDetails.vue'
import HotelDetails from './components/HotelDetails.vue'
import TransportationDetails from './components/TransportationDetails.vue'
import PuzzleDetails from './components/PuzzleDetails.vue'
import BucksHens from './components/BucksHens.vue'
import PreWeddingEvents from './components/PreWeddingEvents.vue'
import FAQ from './components/FAQ.vue'
import ContactDetails from './components/ContactDetails.vue'
import HoneymoonFundDetails from './components/HoneymoonFundDetails.vue'

export default defineComponent({
  name: 'App',
  components: { HeroSectionSmall, WeddingDetails, HotelDetails, TransportationDetails, PuzzleDetails, BucksHens, PreWeddingEvents, FAQ, ContactDetails, HoneymoonFundDetails }
})

