<template>
  <div id="qbootstrap-started" class="qbootstrap-bg" data-section="rsvp"  style="background-image: url(images/SunshineCoastEngagementPhotosBrookeTim-12.jpg);">
    <div class="overlay"></div>
    <div class="container rsvp-section">
      <div class="row animate-box">
        <div class="col-md-10 col-md-offset-1">
          <div class="col-md-12 text-center section-heading svg-sm colored">
            <img src="images/flaticon/svg/005-two.svg" class="svg" alt="">
            <h2>You Are Invited</h2>
            <div class="row">
            <div class="col-md-10 col-md-offset-1 subtext font-weight-bold">
              <h3>We're so excited to spend our magical day together with you!</h3><br />
              <h2 style="font-size: 72px">Please RSVP by September 1st</h2>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="row bottom-buttons animate-box">
        <div class="col-md-3 col-md-offset-3">
          <a href="https://forms.gle/o2nBUFHNPn83pige8" target="_blank" class="btn btn-primary btn-lg btn-block">RSVP</a>
        </div>
        <div class="col-md-3">
          <a href="/details" class="btn btn-primary btn-lg btn-block">Details & FAQ</a>
        </div>
      </div>
    </div>
  </div>
</template>
