<template>
  <div id="qbootstrap-faq" data-section="faq">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>FAQ</h1>
          <p>If you have any questions, feel free to email either of us any time at tim@timjrobinson.com or brookers713@gmail.com. Alternatively, you can contact us via any social media platform through which you would normally connect with us.</p>
          <p>We'll add answers to questions here as they come up.</p>
          <h2>When do I need to RSVP by?</h2>
          <p>Please RSVP by September 1st so we can let our vendors know how many people are attending. Also the hotel block at the Desmond expires September 16th, so make sure you reserve your hotel room before then.</p>
          <h2>Dress code</h2>
          <p>The Dress Code is Semi-Formal - no jeans, shorts, or t-shirts. The temperature will be around 5 to 15 degrees celcius, so you'll want to wear something warm.</p>
        </div>
      </div>
    </div>
  </div>
</template>
