<template>
  <div class="qbootstrap-hero" data-section="home">
    <div class="qbootstrap-overlay"></div>
    <div class="qbootstrap-cover text-center" data-stellar-background-ratio="0.5" style="background-image: url(images/SunshineCoastEngagementBrookeTim-35.jpg);">
      <div class="display-t">
        <div class="display-tc">
          <div class="container">
            <div class="col-md-10 col-md-offset-1">
              <div class="animate-box svg-sm colored">
                <h1 class="holder"><span>The Wedding of</span></h1>
                <h2>Brooke Ann Coco &amp; Timothy Robinson</h2>
                <p>16.10.2022</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
