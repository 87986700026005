<template>
  <div id="qbootstrap-people" data-section="people">
    <div class="container">
      <div class="row animate-box">
        <div class="col-md-8 col-md-offset-2">
          <div class="col-md-12 text-center section-heading svg-sm colored">
            <img src="images/flaticon/svg/005-two.svg" class="svg" alt="">
            <h2>The Groomsmen</h2>
            <div class="row">
            <div class="col-md-10 col-md-offset-1 subtext  ">
              <h3>Hailing from the land downunder, formiddable in stature.</h3>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="row row-bottom-padded-lg">
        <div class="col-md-3 text-center animate-box">
          <div class="groom-men">
            <img src="images/dale-roach.jpg" class="img-responsive" alt="Dale Roach">
            <h3>Dale Roach</h3>
          </div>
        </div>
        <div class="col-md-3 text-center animate-box">
          <div class="groom-men">
            <img src="images/jarryd-dalton.jpg" class="img-responsive" alt="Jarryd Dalton">
            <h3>Jarryd Dalton</h3>
          </div>
        </div>
        <div class="col-md-3 text-center animate-box">
          <div class="groom-men">
            <img src="images/gwil-whittaker.jpg" class="img-responsive" alt="Gwil Whittaker">
            <h3>Gwil Whittaker</h3>
          </div>
        </div>
        <div class="col-md-3 text-center animate-box">
          <div class="groom-men">
            <img src="images/justin-dray.jpg" class="img-responsive" alt="Justin Dray">
            <h3>Justin Dray</h3>
          </div>
        </div>
      </div>
      <div class="row animate-box">
        <div class="col-md-8 col-md-offset-2">
          <div class="col-md-12 text-center section-heading svg-sm colored">
            <img src="images/flaticon/svg/005-two.svg" class="svg" alt="Free HTML5 Bootstrap Template by QBootstrap.com">
            <h2>The Bridesmaids</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 text-center animate-box">
          <div class="groom-men">
            <img src="images/cynthiaan.jpg" class="img-responsive" alt="Cynthiaan Heckelsmiller">
            <h3>Cynthiann Heckelsmiller</h3>
          </div>
        </div>
        <div class="col-md-3 text-center animate-box">
          <div class="groom-men">
            <img src="images/rebecca-jollif.jpg" class="img-responsive" alt="Rebecca Jolliff">
            <h3>Rebecca Jolliff</h3>
          </div>
        </div>
        <div class="col-md-3 text-center animate-box">
          <div class="groom-men">
            <img src="images/juliann-fama.jpg" class="img-responsive" alt="Julianne Fama">
            <h3>Julianne Fama</h3>
          </div>
        </div>
        <div class="col-md-3 text-center animate-box">
          <div class="groom-men">
            <img src="images/cherrod-gweltney.jpg" class="img-responsive" alt="Cherrod Gwaltney">
            <h3>Cherrod Gwaltney</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
