<template>
  <footer id="footer" role="contentinfo">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <ul class="social social-circle">
            <li><a href="http://twitter.com/timjrobinson"><i class="icon-twitter"></i></a></li>
            <li><a href="http://facebook.com/brookeanncoco"><i class="icon-facebook"></i></a></li>
            <li><a href="/puzzle"><i class="icon-puzzle"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
